import React from "react";
import user from "../assets/images/assets/user.png";
import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
function Testimonials() {
  const testimonialsData = [
    {
      img: user,
      author: "Jacob",
      designation: "CEO - IT Solution Company",
      text: "Lorem ipsum dolor sit amet consectetur. Bibendum quis fringilla odio maecenas magna eu ultrices aliquam egestas. Enim phasellus eu commodo nibh vitae amet elit. Ut natoque dui.",
    },
    {
      img: user,
      author: "Sarah",
      designation: "Manager - Software Firm",
      text: "Enim phasellus eu commodo nibh vitae amet elit. Ut natoque dui lorem ipsum dolor sit amet consectetur. Bibendum quis fringilla odio maecenas magna eu ultrices aliquam egestas.",
    },
    {
      img: user,
      author: "Michael",
      designation: "Developer - Tech Startup",
      text: "Bibendum quis fringilla odio maecenas magna eu ultrices aliquam egestas. Lorem ipsum dolor sit amet consectetur. Enim phasellus eu commodo nibh vitae amet elit. Ut natoque dui.",
    },
    {
      img: user,
      author: "Jacob",
      designation: "CEO - IT Solution Company",
      text: "Lorem ipsum dolor sit amet consectetur. Bibendum quis fringilla odio maecenas magna eu ultrices aliquam egestas. Enim phasellus eu commodo nibh vitae amet elit. Ut natoque dui.",
    },
    {
      img: user,
      author: "Sarah",
      designation: "Manager - Software Firm",
      text: "Enim phasellus eu commodo nibh vitae amet elit. Ut natoque dui lorem ipsum dolor sit amet consectetur. Bibendum quis fringilla odio maecenas magna eu ultrices aliquam egestas.",
    },
    {
      img: user,
      author: "Michael",
      designation: "Developer - Tech Startup",
      text: "Bibendum quis fringilla odio maecenas magna eu ultrices aliquam egestas. Lorem ipsum dolor sit amet consectetur. Enim phasellus eu commodo nibh vitae amet elit. Ut natoque dui.",
    },
  ];

  return (
    <section className="testimonials-wrapper bg-gradient-grey-900 py-5 pb-md-5">
      <div className="title-animation">
        <h2 className="section-badge">
          <span className="section-badge-text">Testimonials</span>
        </h2>
        <div className="container-xl">
          <div className="title wow fadeInUp text-center mb-3 mb-md-5">
            <h2 className="title-text text-white fw-bold mb-0">
              Voices of Trust: Client Stories, Testimonials that Illuminate Our
              Shared Success.
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="testimonial-slider-wrapper position-relative">
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
            centeredSlides={true}
            loop={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="swiper testimonialSlider wow fadeInDown py-3 py-lg-5"
          >
            <div className="swiper-wrapper">
              {testimonialsData.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="testimonial-img">
                        <img src={testimonial.img} alt="testimonial" />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="testimonial-content">
                        <div className="testimonial-author-info">
                          <h6 className="testimonial-author">
                            {testimonial.author}
                          </h6>
                          <p className="author-designation">
                            {testimonial.designation}
                          </p>
                        </div>
                        <p className="testimonial-text">{testimonial.text}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div className="testimonial-nav slider-nav-bottom pt-3 pt-xl-5">
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
