import React, { useEffect, useState } from "react";

function Footer() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer bg-gradient-grey-900 py-3 py-md-5">
        <div className="container-xl">
          <div className="footer-top wow fadeInUp">
            <div className="row justify-content-between row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-auto">
              <div className="col mb-3">
                <div className="footer-link-widgets">
                  <h6 className="footer-menu-title">Company</h6>
                  <ul className="footer-menu">
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Blogs</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                    <li>
                      <a href="#">Payment</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col mb-3">
                <div className="footer-link-widgets">
                  <h6 className="footer-menu-title">Expertise Services</h6>
                  <ul className="footer-menu">
                    <li>
                      <a href="#">Web Application Development</a>
                    </li>
                    <li>
                      <a href="#">Mobile Apps Development</a>
                    </li>
                    <li>
                      <a href="#">Dedicated Development Team</a>
                    </li>
                    <li>
                      <a href="#">Frontend Technologies</a>
                    </li>
                    <li>
                      <a href="#">Cloud & DevOps Artificial</a>
                    </li>
                    <li>
                      <a href="#">Intelligence</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col mb-3">
                <div className="footer-link-widgets">
                  <h6 className="footer-menu-title">Enterprise Services</h6>
                  <ul className="footer-menu">
                    <li>
                      <a href="#">Business / Technology Consulting</a>
                    </li>
                    <li>
                      <a href="#">Quality Assurance & Testing</a>
                    </li>
                    <li>
                      <a href="#">Digital Transformation</a>
                    </li>
                    <li>
                      <a href="#">Product Maintenance & Support</a>
                    </li>
                    <li>
                      <a href="#">Cloud Integration</a>
                    </li>
                    <li>
                      <a href="#">Enterprise Content Management</a>
                    </li>
                    <li>
                      <a href="#">IT Metrics & Dashboard</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col mb-3">
                <div className="footer-link-widgets">
                  <h6 className="footer-menu-title">Process</h6>
                  <ul className="footer-menu">
                    <li>
                      <a href="#">Development Methodology</a>
                    </li>
                    <li>
                      <a href="#">Delivery Models</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-white" />
          <div className="footer-bottom text-center">
            <p className="wow fadeInUp">
              © 2024 WebMobi Technologies Inc . All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
      {showButton && (
      <div
        id="scroll"
        title="Scroll to top"
        className="scrollTop icon-box icon-box-md icon-box-circle"
        onClick={scrollToTop}
      >
        <i className="la la-arrow-up"></i>
      </div>)}
    </>
  );
}

export default Footer;
