import React from "react";
import service1 from "../assets/images/assets/service-01.png";
import service2 from "../assets/images/assets/service-02.png";
import service3 from "../assets/images/assets/service-03.png";
import service4 from "../assets/images/assets/service-04.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
function Services() {
  const sliderData = [
    {
      img: service1,
      title: "Artificial Intelligence",
      alt: "Artificial Intelligence Service",
    },
    {
      img: service2,
      title: "Front-End Development",
      alt: "Front-End Development Service",
    },
    {
      img: service3,
      title: "Dedicated Developer",
      alt: "Dedicated Developer Service",
    },
    {
      img: service4,
      title: "Mobile App Development",
      alt: "Mobile App Development Service",
    },
    {
      img: service1,
      title: "Artificial Intelligence",
      alt: "Artificial Intelligence Service",
    },
    {
      img: service2,
      title: "Front-End Development",
      alt: "Front-End Development Service",
    },
    {
      img: service3,
      title: "Dedicated Developer",
      alt: "Dedicated Developer Service",
    },
    {
      img: service4,
      title: "Mobile App Development",
      alt: "Mobile App Development Service",
    },
  ];
  return (
    <>
      <section className="services-wrapper bg-gradient-grey-900 py-3 py-md-5">
        <div className="title-animation">
          <h2 className="section-badge">
            <span className="section-badge-text">Services</span>
          </h2>
          <div className="container-xl">
            <div className="title wow fadeInUp text-center mb-3 mb-md-5">
              <h2 className="title-text text-white fw-bold mb-0">
                Excellence Redefined: Unleashing Tailored Solutions for Your
                Success Journey.
              </h2>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="accordion accordionDark" id="accordionServices">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#serviceOne"
                >
                  <span className="accordion-num">01</span> Entreprise Service
                </button>
              </h2>
              <div
                id="serviceOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionServices"
              >
                <div className="accordion-body">
                  <div className="border-start ps-4">
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Eu amet posuere
                      consequat donec suspendisse lorem enim. Elit viverra nisl
                      non nisl eleifend. Cursus erat mattis fames et mollis
                      risus at.
                    </p>
                  </div>
                  <div className="serviceSlider-wrapper position-relative">
                    <Swiper
                      spaceBetween={30}
                      loop={true}
                      breakpoints={{
                        375: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      modules={[Navigation]}
                      className="swiper entrepriseServiceSlider"
                    >
                      <div class="swiper-wrapper">
                        {sliderData.map((item, index) => (
                          <SwiperSlide key={index}>
                            <a className="service-card" href="#">
                              <div className="service-image">
                                <img
                                  src={item.img}
                                  alt={item.alt}
                                  className="img-fluid"
                                />
                              </div>
                              <h5 className="service-title">{item.title}</h5>
                            </a>
                          </SwiperSlide>
                        ))}
                      </div>
                    </Swiper>
                    <div class="entrepriseSlider-nav d-flex justify-content-center align-items-center">
                      <div class="swiper-button-prev"></div>
                      <div class="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#serviceTwo"
                >
                  <span className="accordion-num">02</span> Expertise Service
                </button>
              </h2>
              <div
                id="serviceTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionServices"
              >
                <div className="accordion-body">
                  <div className="border-start ps-4">
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Eu amet posuere
                      consequat donec suspendisse lorem enim. Elit viverra nisl
                      non nisl eleifend. Cursus erat mattis fames et mollis
                      risus at.
                    </p>
                  </div>
                  <div className="serviceSlider-wrapper position-relative">
                    <Swiper
                      spaceBetween={30}
                      loop={true}
                      freeMode={true}
                      slideToClickedSlide={true}
                      modules={[Navigation]}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      breakpoints={{
                        375: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },
                      }}
                      className="swiper entrepriseServiceSlider"
                    >
                      <div className="swiper-wrapper">
                        {sliderData.map((item, index) => (
                          <SwiperSlide key={index}>
                            <a className="service-card" href="#">
                              <div className="service-image">
                                <img
                                  src={item.img}
                                  alt={item.alt}
                                  className="img-fluid"
                                />
                              </div>
                              <h5 className="service-title">{item.title}</h5>
                            </a>
                          </SwiperSlide>
                        ))}
                      </div>
                    </Swiper>

                    <div className="expertiseSlider-nav d-flex justify-content-center align-items-center">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
