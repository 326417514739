import React from "react";
import WebMobiLogo from '../assets/images/logo/webmobitechnologies-logo-white.png'
function Header() {
  return (
    <>
      <header id="header" className="header py-md-3">
        <div className="container-xl">
          <nav className="navigation-wrapper navbar-light navbar navbar-expand-lg py-md-0 px-0">
            <a className="navbar-brand" href="index.html">
              <img
                src={WebMobiLogo}
                className="img-fluid"
                alt="Webmobi Technologies"
              />
            </a>

            <button
              className="navbar-toggler d-lg-none border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mainNavigation"
              aria-controls="mainNavigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div id="mainNavigation" className="collapse navbar-collapse">
              <ul className="navbar-nav justify-content-end ms-auto font-medium">
                <li className="nav-item active">
                  <a className="nav-link" title="Home" href="#">
                    Company
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    title="Deliery Apps"
                    href="#"
                    data-toggle="dropdown"
                  >
                    Service
                  </a>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item">
                      <a className="nav-link" href="#">
                        Dropdown Item
                      </a>
                    </li>
                    <li className="dropdown-item">
                      <a className="nav-link" href="#">
                        Dropdown Item
                      </a>
                    </li>
                    <li className="dropdown-item">
                      <a className="nav-link" href="#">
                        Dropdown Item
                      </a>
                    </li>
                    <li className="dropdown-item">
                      <a className="nav-link" href="#">
                        Dropdown Item
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" title="About Us" href="#">
                    Product
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" title="Our Clients" href="#">
                    Portfolio
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" title="Blogs" href="#">
                    Contact
                  </a>
                </li>
              </ul>
              <a className="btn btn-primary ms-lg-5" href="#">
                Let's Talk
                <span className="arrow-icon">
                  <svg
                    width="18"
                    height="13"
                    viewBox="0 0 18 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                      fill="white"
                    ></path>
                  </svg>{" "}
                </span>
              </a>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
