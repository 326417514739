// import gsap, { Back, Power2,SplitText } from "gsap";
// import { ScrollTrigger } from "gsap/all";
// import jQuery from "jquery";
// function titleAnimation() {
// 	var tg_var = jQuery(".title-text");
// 	if (!tg_var.length) {
// 		return;
// 	}
// 	const quotes = document.querySelectorAll(".title .title-text");

import gsap, { Power2 } from "gsap";

// 	quotes.forEach((quote) => {
// 		//Reset if needed
// 		if (quote.animation) {
// 			quote.animation.progress(1).kill();
// 			quote.split.revert();
// 		}

// 		var getclass = quote.closest(".title").className;
// 		var animation = getclass.split("animation-");
// 		if (animation[1] == "style4") return;

// 		quote.split = new SplitText(quote, {
// 			type: "lines,words,chars",
// 			linesClass: "split-line",
// 			wordsClass: "split-word",
// 			charsClass: "split-char",
// 		});
// 		gsap.set(quote, { perspective: 400 });

// 		gsap.set(quote.split.chars, {
// 			opacity: 0,
// 			y: "90%",
// 			rotateX: "-40deg",
// 		});

// 		quote.animation = gsap.to(quote.split.chars, {
// 			scrollTrigger: {
// 				trigger: quote,
// 				start: "top 90%",
// 			},
// 			x: "0",
// 			y: "0",
// 			rotateX: "0",
// 			opacity: 1,
// 			duration: 1,
// 			ease: Back.easeOut,
// 			stagger: 0.02,
// 		});
// 	});
// }
// ScrollTrigger.addEventListener("refresh", titleAnimation);

// jQuery(document).ready(function ($) {
// 	jQuery(".statistics-digit").counterUp({
// 		delay: 10,
// 		time: 1000,
// 	});
// });

// TITLE ANIMATION STARTS HERE

function titleMarquee() {
	let sectionBadges = document.querySelectorAll(".section-badge");

	// TITLE CLONE
	function titleClone(times) {
		sectionBadges.forEach((sectionBadge) => {
			let titleClone = sectionBadge.querySelector(".section-badge-text");
			if (titleClone) {
				for (let i = 0; i < times; i++) {
					let clone = titleClone.cloneNode(true);
					sectionBadge.appendChild(clone);
				}
			}
		});
	}

	titleClone(4);
	// GSAP animation on scroll
	const handleWheel = function (ele) {
		gsap.to(sectionBadges, {
			transform: "translateX(-200%)",
			duration: 15,
			repeat: -1,
			ease: "none",
		});
		// if (ele.deltaY > 0) {
		// 	gsap.to(sectionBadges, {
		// 		transform: "translateX(-200%)",
		// 		duration: 10,
		// 		repeat: -1,
		// 		ease: "none",
		// 	});
		// } else {
		// 	gsap.to(sectionBadges, {
		// 		transform: "translateX(0%)",
		// 		duration: 5,
		// 		repeat: -1,
		// 		ease: "none",
		// 	});
		// }
	};

	window.addEventListener("wheel", handleWheel);
}

titleMarquee();

// TITLE ANIMATION  ENDS HERE

// PORTFOLIO IMAGE ANIMATION

// gsap.registerPlugin(ScrollTrigger);

// let revealContainers = document.querySelectorAll(".revealImg");

// revealContainers.forEach((container) => {
// 	let image = container.querySelector("img");
// 	let tl = gsap.timeline({
// 		scrollTrigger: {
// 			trigger: container,
// 			toggleActions: "restart none none reset",
// 		},
// 	});

// 	tl.set(container, { autoAlpha: 1 });
// 	tl.from(container, 1.5, {
// 		xPercent: -100,
// 		ease: Power2.out,
// 	});
// 	tl.from(image, 1.5, {
// 		xPercent: 100,
// 		scale: 1.3,
// 		delay: -1.5,
// 		ease: Power2.out,
// 	});
// });
