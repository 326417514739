import React, { useEffect, useRef, useState } from "react";
import aboutImg from "../assets/images/assets/about-image.png";
import gsap from "gsap";
function AboutUs() {
  const titleRef = useRef(null);
  const countRef = useRef(0);
  const [gsapCount, setGsapCount] = useState(0);

  useEffect(() => {
   gsap.to(titleRef.current, {
      x: 200,
      duration: 1,
      repeat: -1,
      yoyo: true,
      ease: "none",
      onRepeat: () => {
        countRef.current += 1;
        setGsapCount(countRef.current);
      }
    });
  }, []);
  
  return (
    <>
      <section className="about-wrapper bg-gradient-grey-700 py-3 py-md-5">
        <div className="title-animation">
          <h2 className="section-badge">
            <span className="section-badge-text" ref={titleRef}>
              About{" "}
            </span>
          </h2>
          <div className="container-xl">
            <div className="title wow fadeInUp text-center mb-3 mb-md-5">
              <h2 className="title-text text-white fw-bold mb-0">
                Epic Beginnings: Unveiling Our Essence, Crafting Futures with
                Excellence.
              </h2>
            </div>
          </div>
        </div>

        <div className="container-xl">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="image-module mb-4 pb-5 pb-md-0 wow fadeInUp">
                <img src={aboutImg} alt="About" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-info text-white">
                <h2 className="text-white mb-4">
                  Empowering Progress: Our Story, Your Journey, Shared
                  Excellence.
                </h2>
                <h5 className="text-white mb-3">
                  Empowering Progress: Our Story, Your Journey, Shared
                  Excellence.
                </h5>
                <h5 className="text-white">
                  Embark on a journey through our narrative, where innovation
                  meets purpose.
                </h5>
                <p className="lead">
                  Lorem ipsum dolor sit amet consectetur. Sit non diam justo
                  fames. Blandit et purus mollis convallis malesuada egestas
                  risus quam enim. Semper lorem rhoncus et felis tristique
                  tellus volutpat orci. Dui elementum a sed.
                </p>
              </div>
              <div className="btn-panel pt-3 pt-md-5">
                <a href="" className="btn btn-outline-white">
                  Who We Are
                  <span className="arrow-icon">
                    <svg
                      width="18"
                      height="13"
                      viewBox="0 0 18 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
