import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function Statistics() {
	const { ref, inView } = useInView({
		triggerOnce: true, // Trigger the animation only once
		threshold: 0.1, // Trigger when 10% of the element is visible
	  });

  return (
    <>
      <section ref={ref} className="statistics-wrapper bg-dark py-3 py-md-5">
        <div className="container-xl">
          <div className="row row-vr-devider justify-content-between align-items-center">
            <div className="col-sm-6 col-md-3 col-lg-3 wow fadeInRight">
              <div className="card statistics-card">
                <div className="card-body py-0">
                  <h2 className="statistics-num">
                    <span className="statistics-digit">
                      {inView  && <CountUp end={400} duration={2} />}
                    </span>{" "}
                    +
                  </h2>
                  <h6 className="statistics-title">Projects Competed</h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 wow fadeInLeft">
              <div className="card statistics-card">
                <div className="card-body py-0">
                  <h2 className="statistics-num">
                    <span className="statistics-digit">
                      {inView  && <CountUp end={15} duration={2} />}
                    </span>
                    +
                  </h2>
                  <h6 className="statistics-title">Successful Years</h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 wow fadeInRight">
              <div className="card statistics-card">
                <div className="card-body py-0">
                  <h2 className="statistics-num">
                    <span className="statistics-digit">
                      {inView  && <CountUp end={98} duration={2} />}
                    </span>
                    %
                  </h2>
                  <h6 className="statistics-title">Client Retention</h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 wow fadeInLeft">
              <div className="card statistics-card">
                <div className="card-body py-0">
                  <h2 className="statistics-num">
                    <span className="statistics-digit">
                      {inView  && <CountUp end={30} duration={2} />}
                    </span>
                    +
                  </h2>
                  <h6 className="statistics-title">Countries</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Statistics;
