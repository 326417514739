import React, { useState } from "react";
import canada from "../assets/images/assets/canada-flag.png";
import india from "../assets/images/assets/india-flag.png";
import uae from "../assets/images/assets/uae.svg";
function Support() {
	const [formData, setFormData] = useState({
		name: '',
		company: '',
		email: '',
		message: '',
	  });
	
	  const [validated, setValidated] = useState(false);
	
	  const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
	  };
	
	  const handleSubmit = (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.preventDefault();
		  e.stopPropagation();
		} else {
		  e.preventDefault();
		 console.log(formData)
		}
		setValidated(true);
	  };
  return (
    <>
      <section className="supports-wrapper bg-gradient-grey-900 py-3 py-5">
        <div className="title-animation">
          <h2 className="section-badge">
            <span className="section-badge-text">Supports</span>
          </h2>
          <div className="container-xl">
            <div className="title wow fadeInUp text-center mb-3 mb-md-5">
              <h2 className="title-text text-white fw-bold mb-0">
                Voices of Trust: Client Stories, Testimonials that Illuminate
                Our Shared Success.
              </h2>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 col-lg-5">
              <div className="supports-from form-dark mb-3 mb-md-0">
                <div className="card card-supports-form bg-gradient-grey-900">
                  <div className="card-body p-lg-5">
                    <h4 className="supports-from-title mb-4 mb-md-5">
                      Your Vision, Our Mission: Let's Shape Success Together.
                    </h4>
                    <form
                      noValidate
                      validated={validated.toString()}
                      onSubmit={handleSubmit}
                    >
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className={`form-control ${
                            validated && !formData.name
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="Name">Name</label>
                        <div className="invalid-feedback">Name is required</div>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className={`form-control ${
                            validated && !formData.company
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          placeholder="Company"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="Company">Company</label>
                        <div className="invalid-feedback">
                          Company is required
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className={`form-control ${
                            validated && !formData.email
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="Email">Email</label>
                        <div className="invalid-feedback">
                          Email is required
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea
                          className={`form-control ${
                            validated && !formData.message
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          placeholder="Message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                        <label htmlFor="Message">Message</label>
                        <div className="invalid-feedback">
                          Message is required
                        </div>
                      </div>
                      <div className="mb-3">
                        <button type="submit" className="btn btn-white">
                          Submit
                          <span className="arrow-icon">
                            <svg
                              width="18"
                              height="13"
                              viewBox="0 0 18 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                fill="white"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-6">
              <div className="branch-location-wrapper">
                <h4 className="branch-location-title mb-4 mb-md-5">
                  <span className="d-block text-md mb-3">
                    Let's get in touch
                  </span>
                  Connect for Excellence: Your Gateway to Exceptional Solutions.
                </h4>
                <div className="row row-cols-1 row-cols-md-2">
                  <div className="cols">
                    <div className="branch-location">
                      <div className="country-flag">
                        <span className="flag">
                          <img src={canada} alt="Canada" />
                        </span>
                      </div>
                      <h5 className="branch-head">Corporate Office</h5>
                      <p className="branch-address">
                        2 County Court Blvd., Suite 400, Brampton, Ontario L6W
                        3W8
                      </p>
                    </div>
                  </div>

                  <div className="cols">
                    <div className="branch-location">
                      <div className="country-flag">
                        <span className="flag">
                          <img src={india} alt="India" />
                        </span>
                      </div>
                      <h5 className="branch-head">Development Office</h5>
                      <p className="branch-address">
                        102, Olive, Opp ABS Tower, OP Road- 390007, Vadodara,
                        Gujarat, India.
                      </p>
                    </div>
                  </div>

                  <div className="cols">
                    <div className="branch-location">
                      <div className="country-flag">
                        <span className="flag">
                          <img src={uae} alt="UAE" />
                        </span>
                      </div>
                      <h5 className="branch-head">Branch Office</h5>
                      <p className="branch-address">
                        48th floor, Aspin Commercial Tower, Sheikh Zayed Road,
                        Dubai, UAE
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer-widgets mt-lg-4 wow fadeInDown">
                  <h6 className="footer-widgets-title text-white">
                    Be Socialized
                  </h6>
                  <div className="social-networks my-3 py-2 wow fadeInDown">
                    <a className="facebook me-3" href="#">
                      <i className="la la-facebook-f"></i>
                    </a>
                    <a className="twitter me-3" href="#">
                      <i className="la la-twitter"></i>
                    </a>
                    <a className="linkedin me-3" href="#">
                      <i className="la la-linkedin"></i>
                    </a>
                    <a className="instagram me-3" href="#">
                      <i className="la la-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Support;
