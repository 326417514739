import "./App.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "swiper/css";
import "./assets/styles/fonts.css";
import "./assets/styles/line-awesome.min.css";
import "./assets/styles/animate.css";
import "./assets/styles/swiper.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/theme12.css";
import WOW from "wowjs";
import { useEffect } from "react";
import "./assets/js/animation";
import useTitleMarquee from "./Hooks/useTitleMarquee";
import useImageReveal from "./Hooks/useImageReveal";
import Home from "./pages/Home";

function App() {
  useTitleMarquee();
  // useImageReveal();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <Home />
    </>
  );
}

export default App;
