import React, { useEffect } from "react";
import pateltaxation from "../assets/images/assets/pateltaxation.jpg";
import html from "../assets/images/assets/html.svg";
import css from "../assets/images/assets/css.svg";
import python from "../assets/images/assets/python.svg";
import wordpress from "../assets/images/assets/wordpress.svg";
import innovxa from "../assets/images/assets/innovxa-new.jpg";
import mindConnect from "../assets/images/assets/mind-connect.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
function PortFolio() {
  return (
    <>
      <section className="portfolio-wrapper bg-gradient-grey-900 py-3 py-md-5">
        <div className="title-animation">
          <h2 className="section-badge">
            <span className="section-badge-text">Portfolio</span>
          </h2>
          <div className="container-xl">
            <div className="title wow fadeInUp text-center mb-3 mb-md-5">
              <h2 className="title-text text-white fw-bold mb-0">
                From Challenge to Victory: Exploring Case Studies of Innovation
                and Excellence
              </h2>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="portfolio-slider mt-2 mt-md-5">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              loop={true}
              modules={[ Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              className="swiper portfolioSlider"
            >
              <div className="swiper-wrapper">
                <SwiperSlide >
                  <div className="row justify-content-between">
                    <div className="col-md-6">
                      <div className="portfolio-image revealImg mb-3 mb-md-0">
                        <img
                          src={pateltaxation}
                          alt="portfolio"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="portfolio-info">
                        <h6 className="portfolio-catgory">Category</h6>
                        <h4 className="portfolio-title">Dave Financial CRM</h4>
                        <h5 className="portfolio-text">
                          Lorem ipsum dolor sit amet consectetur. Eu lobortis
                          aliquet nec dui blandit faucibus proin vitae
                          adipiscing.
                        </h5>
                        <div className="portfolio-tech-wrap my-3 my-md-5">
                          <h6 className="portfolio-tech">Technology</h6>
                          <div className="tech-logo-module d-flex">
                            <div className="tech-logo">
                              <img src={html} alt="HTML" />
                            </div>
                            <div className="tech-logo">
                              <img src={css} alt="CSS" />
                            </div>
                            <div className="tech-logo">
                              <img src={python} alt="python" />
                            </div>
                            <div className="tech-logo">
                              <img src={wordpress} alt="wordpress" />
                            </div>
                          </div>
                        </div>

                        <a href="#" className="btn btn-outline-white">
                          View Case Study
                          <span className="arrow-icon">
                            <svg
                              width="18"
                              height="13"
                              viewBox="0 0 18 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                fill="white"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide >
                  <div className="row justify-content-between">
                    <div className="col-md-6">
                      <div className="portfolio-image revealImg mb-3 mb-md-0">
                        <img
                          src={innovxa}
                          alt="portfolio"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="portfolio-info">
                        <h6 className="portfolio-catgory">Category</h6>
                        <h4 className="portfolio-title">Dave Financial CRM</h4>
                        <h5 className="portfolio-text">
                          Lorem ipsum dolor sit amet consectetur. Eu lobortis
                          aliquet nec dui blandit faucibus proin vitae
                          adipiscing.
                        </h5>
                        <div className="portfolio-tech-wrap my-3 my-md-5">
                          <h6 className="portfolio-tech">Technology</h6>
                          <div className="tech-logo-module d-flex">
                            <div className="tech-logo">
                              <img src={html} alt="HTML" />
                            </div>
                            <div className="tech-logo">
                              <img src={css} alt="CSS" />
                            </div>
                            <div className="tech-logo">
                              <img src={python} alt="python" />
                            </div>
                            <div className="tech-logo">
                              <img src={wordpress} alt="wordpress" />
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn btn-outline-white">
                          View Case Study
                          <span className="arrow-icon">
                            <svg
                              width="18"
                              height="13"
                              viewBox="0 0 18 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                fill="white"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide >
                  <div className="row justify-content-between">
                    <div className="col-md-6">
                      <div className="portfolio-image revealImg mb-3 mb-md-0">
                        <img
                          src={mindConnect}
                          alt="portfolio"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="portfolio-info">
                        <h6 className="portfolio-catgory">Category</h6>
                        <h4 className="portfolio-title">Dave Financial CRM</h4>
                        <h5 className="portfolio-text">
                          Lorem ipsum dolor sit amet consectetur. Eu lobortis
                          aliquet nec dui blandit faucibus proin vitae
                          adipiscing.
                        </h5>
                        <div className="portfolio-tech-wrap my-3 my-md-5">
                          <h6 className="portfolio-tech">Technology</h6>
                          <div className="tech-logo-module d-flex">
                            <div className="tech-logo">
                              <img src={html} alt="HTML" />
                            </div>
                            <div className="tech-logo">
                              <img src={css} alt="CSS" />
                            </div>
                            <div className="tech-logo">
                              <img src={python} alt="python" />
                            </div>
                            <div className="tech-logo">
                              <img src={wordpress} alt="wordpress" />
                            </div>
                          </div>
                        </div>
                        <a href="#" className="btn btn-outline-white">
                          View Case Study
                          <span className="arrow-icon">
                            <svg
                              width="18"
                              height="13"
                              viewBox="0 0 18 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                fill="white"
                              ></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
              <div className="portfolio-nav slider-nav-bottom pt-3 pt-md-5">
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default PortFolio;
