import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import Statistics from "../components/Statistics";
import Solutions from "../components/Solutions";
import Services from "../components/Services";
import PortFolio from "../components/PortFolio";
import Testimonials from "../components/Testimonials";
import Support from "../components/Support";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Header />
      <Banner />
      <AboutUs />
      <Statistics />
      <Solutions />
      <Services />
      <PortFolio />
      <Testimonials />
      <Support />
      <Footer />
    </>
  );
}

export default Home;
