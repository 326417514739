import React from "react";

function Solutions() {
  return (
    <>
      <section className="solutions-wrapper bg-gradient-grey-800 py-3 py-md-5">
        <div className="title-animation">
          <h2 className="section-badge">
            <span className="section-badge-text">Solutions</span>
          </h2>
          <div className="container-xl">
            <div className="title wow fadeInUp text-center mb-3 mb-md-5">
              <h2 className="title-text text-white fw-bold mb-0">
                Your Aspiration, Our Expertise: Tailored Services for Unmatched
                Excellence.
              </h2>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row g-4 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4">
            <div className="cols">
              <div className="card feature-card feature-card-dark h-100 shadow-sm wow fadeInUp">
                <div className="card-body p-md-5">
                  <div className="feature-head">
                    <span className="icon-box icon-box-md icon-primary">
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.125 46.5V35.25M7.125 12.75V1.5M1.5 7.125H12.75M1.5 40.875H12.75M26.25 3.75L22.3481 13.8949C21.7136 15.5447 21.3963 16.3696 20.9029 17.0634C20.4657 17.6784 19.9284 18.2157 19.3134 18.6529C18.6196 19.1463 17.7947 19.4636 16.1449 20.0981L6 24L16.1449 27.9019C17.7947 28.5364 18.6196 28.8537 19.3134 29.3471C19.9284 29.7843 20.4657 30.3216 20.9029 30.9366C21.3963 31.6304 21.7136 32.4553 22.3481 34.1051L26.25 44.25L30.1519 34.1051C30.7864 32.4553 31.1037 31.6304 31.5971 30.9366C32.0343 30.3216 32.5716 29.7843 33.1866 29.3471C33.8804 28.8537 34.7053 28.5364 36.3551 27.9019L46.5 24L36.3551 20.0981C34.7053 19.4636 33.8804 19.1463 33.1866 18.6529C32.5716 18.2157 32.0343 17.6784 31.5971 17.0634C31.1037 16.3696 30.7864 15.5447 30.1519 13.8949L26.25 3.75Z"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h4 className="feature-title fw-medium">Deliver On Time</h4>
                  </div>
                  <p>
                    Timely precision, where promises meet performance, ensuring
                    your project's success.
                  </p>
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="card feature-card feature-card-dark h-100 shadow-sm wow fadeInUp">
                <div className="card-body p-md-5">
                  <div className="feature-head">
                    <span className="icon-box icon-box-md icon-primary">
                      <svg
                        width="40"
                        height="48"
                        viewBox="0 0 40 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.25 22.8751L17.75 27.3751L27.875 17.2501M38 24.0001C38 35.0441 25.9536 43.0765 21.5705 45.6335C21.0723 45.9242 20.8233 46.0695 20.4718 46.1448C20.199 46.2033 19.801 46.2033 19.5282 46.1448C19.1767 46.0695 18.9277 45.9242 18.4295 45.6335C14.0464 43.0765 2 35.0441 2 24.0001V13.2397C2 11.4408 2 10.5413 2.29421 9.76816C2.55412 9.08514 2.97646 8.4757 3.52473 7.99252C4.14536 7.44556 4.98755 7.12974 6.67191 6.49811L18.736 1.97409C19.2037 1.79868 19.4376 1.71097 19.6782 1.67621C19.8916 1.64537 20.1084 1.64537 20.3218 1.67621C20.5624 1.71097 20.7963 1.79868 21.264 1.97409L33.3281 6.49811C35.0125 7.12975 35.8546 7.44556 36.4753 7.99252C37.0235 8.4757 37.4459 9.08514 37.7058 9.76816C38 10.5413 38 11.4408 38 13.2397V24.0001Z"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h4 className="feature-title fw-medium">Security</h4>
                  </div>
                  <p>
                    Timely precision, where promises meet performance, ensuring
                    your project's success.
                  </p>
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="card feature-card feature-card-dark h-100 shadow-sm wow fadeInUp">
                <div className="card-body p-md-5">
                  <div className="feature-head">
                    <span className="icon-box icon-box-md icon-primary">
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.875 7.125C13.875 4.0184 16.3934 1.5 19.5 1.5C22.6066 1.5 25.125 4.0184 25.125 7.125V10.5H27.375C30.5201 10.5 32.0927 10.5 33.3331 11.0138C34.9871 11.6989 36.3011 13.0129 36.9862 14.6669C37.5 15.9073 37.5 17.4799 37.5 20.625H40.875C43.9816 20.625 46.5 23.1434 46.5 26.25C46.5 29.3566 43.9816 31.875 40.875 31.875H37.5V35.7C37.5 39.4804 37.5 41.3705 36.7643 42.8144C36.1172 44.0845 35.0845 45.1172 33.8144 45.7643C32.3705 46.5 30.4804 46.5 26.7 46.5H25.125V42.5625C25.125 39.7666 22.8584 37.5 20.0625 37.5C17.2666 37.5 15 39.7666 15 42.5625V46.5H12.3C8.51965 46.5 6.62947 46.5 5.18556 45.7643C3.91547 45.1172 2.88285 44.0845 2.23571 42.8144C1.5 41.3705 1.5 39.4804 1.5 35.7V31.875H4.875C7.9816 31.875 10.5 29.3566 10.5 26.25C10.5 23.1434 7.9816 20.625 4.875 20.625H1.5C1.5 17.4799 1.5 15.9073 2.01381 14.6669C2.6989 13.0129 4.01295 11.6989 5.66689 11.0138C6.90734 10.5 8.4799 10.5 11.625 10.5H13.875V7.125Z"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h4 className="feature-title fw-medium">Flexibility</h4>
                  </div>
                  <p>
                    Timely precision, where promises meet performance, ensuring
                    your project's success.
                  </p>
                </div>
              </div>
            </div>
            <div className="cols">
              <div className="card feature-card feature-card-dark h-100 shadow-sm wow fadeInUp">
                <div className="card-body p-md-5">
                  <div className="feature-head">
                    <span className="icon-box icon-box-md icon-primary">
                      <svg
                        width="30"
                        height="48"
                        viewBox="0 0 30 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5 33C1.5 37.9706 5.52944 42 10.5 42H19.5C24.4706 42 28.5 37.9706 28.5 33C28.5 28.0294 24.4706 24 19.5 24H10.5C5.52944 24 1.5 19.9706 1.5 15C1.5 10.0294 5.52944 6 10.5 6H19.5C24.4706 6 28.5 10.0294 28.5 15M15 1.5V46.5"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h4 className="feature-title fw-medium">Pricing</h4>
                  </div>
                  <p>
                    Timely precision, where promises meet performance, ensuring
                    your project's success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Solutions;
