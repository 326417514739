import { useEffect } from "react";
import { gsap } from "gsap";

const useTitleMarquee = (times = 6) => {
  useEffect(() => {
    const sectionBadges = document.querySelectorAll(".section-badge");

    // TITLE CLONE
    const titleClone = (times) => {
      sectionBadges.forEach((sectionBadge) => {
        let titleClone = sectionBadge.querySelector(".section-badge-text");
        if (titleClone) {
          for (let i = 0; i < times; i++) {
            let clone = titleClone.cloneNode(true);
            sectionBadge.appendChild(clone);
          }
        }
      });
    };

    titleClone(times);

    // GSAP animation on scroll
    const handleWheel = () => {
      gsap.to(sectionBadges, {
        transform: "translateX(-200%)",
        duration: 15,
        repeat: -1,
        ease: "none",
      });
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [times]);
};

export default useTitleMarquee;
