import React from "react";
import BannerImg from "./../assets/images/assets/banner-image.png";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

function Banner() {
  const slidesData = [
    {
      id: 1,
      image: BannerImg,
      title:
        "Innovate Today, Transform Tomorrow: Your Digital Solutions Partner.",
      subtitle:
        "Transforming Ideas into Robust Reality, Building Tomorrow's Software Today.",
      link: "#",
    },
    {
      id: 2,
      image: BannerImg,
      title: "Empower Your Business with Cutting-Edge Technology.",
      subtitle: "Delivering Excellence through Innovation and Expertise.",
      link: "#",
    },
    {
      id: 3,
      image: BannerImg,
      title: "Empower Your Business with Cutting-Edge Technology.",
      subtitle: "Delivering Excellence through Innovation and Expertise.",
      link: "#",
    },
    {
      id: 4,
      image: BannerImg,
      title: "Empower Your Business with Cutting-Edge Technology.",
      subtitle: "Delivering Excellence through Innovation and Expertise.",
      link: "#",
    },
  ];
  return (
    <>
      <section className="banner-wrapper bg-gradient-grey-900 position-relative py-0 py-md-5">
        <div className="container-xl">
          <div className="heroSlider-wrapper position-relative py-0 py-md-5">
            <Swiper
              // effect={"coverflow"}
              grabCursor={false}
              centeredSlides={true}
              loop={true}
              slidesPerView={"auto"}
              // coverflowEffect={{
              //   rotate: 0,
              //   stretch: 80,
              //   depth: 350,
              //   modifier: 1,
              //   slidesShadow: true,
              // }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              modules={[Navigation]}
              className="swiper heroSlider"
            >
              <div className="swiper-wrapper">
                {slidesData.map((slide) => (
                  <SwiperSlide key={slide.id} className="swiper-slide">
                    <div className="banner-caption">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6">
                          <div className="banner-text py-3 py-md-5 d-md-block wow fadeInRight">
                            <h2 className="banner-title mb-md-4">
                              {slide.title}
                            </h2>
                            <h4 className="banner-subtitle text-xl mb-3">
                              {slide.subtitle}
                            </h4>
                            <div className="banner_btns py-lg-3">
                              <a href="" className="btn btn-outline-white">
                                More Info
                                <span className="arrow-icon">
                                  <svg
                                    width="18"
                                    height="13"
                                    viewBox="0 0 18 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                      fill="white"
                                    />
                                  </svg>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="banner-image wow fadeInLeft">
                            <img
                              src={slide.image}
                              alt="banner"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>

            <div className="heroSlider-nav d-flex justify-content-center align-items-center">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
            {/* <div className="swiper heroSlider">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="banner-caption">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-6">
                        <div className="banner-text py-3 py-md-5 d-md-block wow fadeInRight">
                          <h2 className="banner-title mb-md-4">
                            Innovate Today, Transform Tomorrow: Your Digital
                            Solutions Partner.
                          </h2>
                          <h4 className="banner-subtitle text-xl mb-3">
                            Transforming Ideas into Robust Reality, Building
                            Tomorrow's Software Today.
                          </h4>
                          <div className="banner_btns py-lg-3">
                            <a href="" className="btn btn-outline-white">
                              More Info
                              <span className="arrow-icon">
                                <svg
                                  width="18"
                                  height="13"
                                  viewBox="0 0 18 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="banner-image wow fadeInLeft">
                          <img src={BannerImg} alt="banner" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="banner-caption">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-6">
                        <div className="banner-text py-3 py-md-5 d-md-block wow fadeInRight">
                          <h2 className="banner-title mb-md-4">
                            Innovate Today, Transform Tomorrow: Your Digital
                            Solutions Partner.
                          </h2>
                          <h4 className="banner-subtitle text-xl mb-3">
                            Transforming Ideas into Robust Reality, Building
                            Tomorrow's Software Today.
                          </h4>

                          <div className="banner_btns py-lg-3">
                            <a href="" className="btn btn-outline-white">
                              More Info
                              <span className="arrow-icon">
                                <svg
                                  width="18"
                                  height="13"
                                  viewBox="0 0 18 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.8 5.7C14.2 5.7 11.9 3.4 11.9 0.8V0H10.4V0.8C10.4 2.7 11.4 4.5 12.8 5.7H0V7.2H12.7C11.3 8.4 10.3 10.2 10.3 12.1V12.9H11.8V12.1C11.8 9.5 14.1 7.2 16.7 7.2H17.5V5.7H16.8Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="banner-image wow fadeInLeft">
                          <img src={BannerImg} alt="banner" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
